<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in imageList" :key="index">
        <img v-lazy="item" @click="handler(index)" :class="{active:currentIndex==index}" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "ImageList",
  props: ["imageList"],
  data() {
    return {
      currentIndex: 0,
    };
  },
  watch: {
    imageList() {
      //组件实例的方法$nextTick
      this.$nextTick(() => {
        //初始化swiper实例
        new Swiper(this.$refs.cur, {
          //设置图片同时展示几个（slider）
          slidesPerView: 3,
          //设置每一次切换，切换几张图
          slidesPerGroup: 2,
          //滚动滚轮切换
          mousewheel: true,
        });
      });
    },
  },
  methods: {
    handler(index) {
      this.currentIndex = index;
      //通过全局事件总线：通知兄弟当前显示第几张图
      this.$bus.$emit('send',index);
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-container {
  margin-top: 8px;
  height: 80px;
  width: 416px;
  box-sizing: border-box;
  .swiper-slide {
    width: 74px;
    height: 74px;
    margin-right: 11px;
    img {
      border: 1px solid #ccc;
      padding: 2px;
      width: 100%;
      height: 100%;
      width: 70px;
      height: 70px;
      display: block;
      &.active {
        border: 2px solid #f60;
        padding: 1px;
      }
    }
  }
}
</style>