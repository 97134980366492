<template>
  <div class="detail">
    <div class="octopus-detail">
      <!-- 轮播图以及它的介绍 -->
      <div class="octopus-detail-swiper">
          <!-- 左侧放大镜区域 -->
          <div class="octopus-detail-swiper-left">
            <!--放大镜效果-->
            <Zoom :imageList="goodsInfoData.more_thumbs" />
            <!-- 小图列表 -->
            <ImageList :imageList="goodsInfoData.more_thumbs" />
          </div>
          <div class="octopus-detail-swiper-left-top"></div>
          <!-- 上面盒子结束 -->

        <!-- 左边轮播图的盒子结束 -->
        <!-- 右边轮播图的图片开始 -->
        <div class="octopus-detail-swiper-right">
          <!-- 分为上下两部分 -->
          <div class="octopus-detail-swiper-right-top">
            <h2>新品推荐</h2>
            <p>{{ goodsInfoData.name }}</p>
            <span class="octopus-detail-swiper-right-top-bianhao"
              >商品编号: {{ goodsInfoData.bn }}</span
            >
            <!-- 下面积分是一个整体 -->
            <div class="octopus-detail-swiper-right-top-jifen">
              <!-- 积分分为上下两部分 -->
              <div class="octopus-detail-swiper-right-top-jifen-fen">
                <h3>{{ goodsInfoData.price }}</h3>
                <strong>积分</strong
                ><span>￥{{ goodsInfoData.mkt_price }}</span>
              </div>
              <div class="octopus-detail-swiper-right-top-jifen-duihuan">
                <span>注意：赠品不支持单独购买，只能使用积分兑换</span>
                <span>已兑换 {{ goodsInfoData.sale_count }} 件</span>
              </div>
            </div>
          </div>
          <div class="octopus-detail-swiper-right-bottom">
            <div class="octopus-detail-swiper-right-bottom-font">
              <strong>兑换数量</strong>
              <div class="octopus-detail-swiper-right-bottom-kucun">
                <button class="octopus-reduce" @click="minus">-</button>
                <input type="number" v-model.number="buyCount" />
                <button class="octopus-add" @click="add">+</button>
                <span>库存 {{ goodsInfoData.stock_count }} 件</span>
              </div>
              <div class="octopus-detail-swiper-right-bottom-kucun-duihuan">
                <button class="button-blue-232" @click="toCheckPage()">立即兑换</button>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边轮播图的图片结束 -->
      </div>
      <!-- 中间的分界线 -->
      <div class="hr-1200"></div>
      <!-- 下面推荐商品 -->
      <div class="cup-detail">
        <!-- 推荐商品 -->
        <div class="cup-t">
          <!-- 推荐上的button按钮 -->
          <div class="cup-button">
            <button class="button-blue-232">推荐商品</button>
          </div>
          <!-- 下面的cup -->
          <Cup
            :catId="catId"
            :goodsId="goodsId"
            :target="'replace'"
            @refresh-goods="cupGoGoodsPage"
          ></Cup>
        </div>
        <!-- 产品详情 -->
        <div class="cup-d">
          <!-- 详情下的图片按一下的来完成 -->
          <main>产品详情</main>
          <div class="img-box">
            <pre v-html="goodsInfoData.intro"></pre>
            <div style="width:100%;text-align: center">
               <img
                :src="pic"
                v-for="(pic, k) in goodsInfoData.intro_pics"
                :key="k"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 产品详情 -->
    </div>
  </div>
</template>

<script>
import { goodsInfo } from "@/api/goods";
import Cup from "@/components/Cup/Cup.vue";
import ImageList from "./ImageList";
import Zoom from "./Zoom";
export default {
  components: { Cup,ImageList,Zoom },
  name: "detail",
  data() {
    return {
      goodsId: 0,
      catId: 0,
      productId: 0,
      buyCount: 1,
      goodsInfoData: {
        more_thumbs: []
      },
      productData: {},
    };
  },
  mounted() {
    if (parseInt(this.$route.params.goodsId) > 0) {
      this.goodsId = parseInt(this.$route.params.goodsId);
      this.catId = parseInt(this.$route.params.catId);
      this.loadInfo();
    }
  },
  methods: {
    async loadInfo() {
      const res = await goodsInfo({ id: this.goodsId });
      if (res.statusCode == 200) {
        this.goodsInfoData = res.data.goods;
        this.productData = res.data.products;
      }
    },
    toCheckPage() {
      if( !this.$store.getters["useUserStore/getToken"] ){
        this.$notify({
          type: 'error',
          message: '请先登录！'
        })
        this.$redirectUrl.jumpTo('/login')
        return false;
      }
      if (this.buyCount > this.goodsInfoData.stock_count) {
        this.$notify({
          type: 'warning',
          message: '商品库存不足'
        })
        return;
      }
      this.$router.push({
        path: "/check/"+this.goodsId+'/'+this.productId+'/'+this.buyCount,
      });
    },
    cupGoGoodsPage(goodsId) {
      this.goodsId = goodsId;
      this.loadInfo();
    },
    //加法操作
    add() {
      if (this.buyCount >= this.goodsInfoData.stock_count) {
        this.$notify({
          type: 'warning',
          message: '商品库存不足'
        })
        return;
      }
      this.buyCount = parseInt(this.buyCount) + 1;
    },
    //减法操作
    minus() {
      if (this.buyCount <= 1) {
        this.$notify({
          type: 'warning',
          message: '至少需要买一件'
        })
        return;
      }
      this.buyCount -= 1;
    },
  },
};
</script>

<style lang="less" scoped>
.octopus-detail {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  .octopus-detail-swiper {
    display: flex;
    width: 1200px;
    .octopus-detail-swiper-left {
      width: 416px;
      height: 504px;
    }
    .octopus-detail-swiper-right {
      width: 760px;
      height: 439px;
      margin-left: 24px;
      .octopus-detail-swiper-right-top {
        h2 {
          font-size: 12px;
          color: #e4007f;
        }
        p {
          width: 528px;
          font-size: 18px;
          color: #000000;
          margin: 12px 0 21px 0;
        }
        .octopus-detail-swiper-right-top-bianhao {
          height: 12px;
          font-size: 12px;
          color: #575757;
          margin-bottom: 12px;
          display: block;
        }
        .octopus-detail-swiper-right-top-jifen {
          width: 760px;
          height: 76px;
          background: #efefef;
          .octopus-detail-swiper-right-top-jifen-fen {
            position: relative;
            top: 20px;
            margin-left: 10px;
            margin-bottom: 25px;
            h3 {
              font-size: 18px;
              color: #e4007f;
              display: inline-block;
            }
            strong {
              font-size: 14px;
              color: #e4007f;
              margin: 0 20px 0 10px;
            }
            span {
              font-size: 14px;
              color: #575757;
              text-decoration: line-through;
            }
          }
          .octopus-detail-swiper-right-top-jifen-duihuan {
            margin-left: 10px;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            span {
              margin-top: 5px;
              display: block;
            }
            & > span:last-of-type {
              margin-right: 20px;
              color: #575757;
            }
          }
        }
      }
      .octopus-detail-swiper-right-bottom {
        .octopus-detail-swiper-right-bottom-font {
          margin-top: 20px;
          strong {
            font-size: 14px;
            color: #595757;
          }
          .octopus-detail-swiper-right-bottom-kucun {
            margin: 12px 0 0px 0;
            button {
              width: 36px;
              height: 24px;
              background: #efefef;
              border: none;
              cursor: pointer;
              font-size: 14px;
              color: #474747;
              line-height: 24px;
            }
            input {
              width: 72px;
              line-height: 24px;
              background: #efefef;
              margin: 0 10px;
              text-align: center;
              border: none;
              cursor: pointer;
            }
            .octopus-add {
              margin-right: 10px;
            }
            span {
              font-size: 14px;
              color: #575757;
            }
          }
          .octopus-detail-swiper-right-bottom-kucun-duihuan {
            margin-top: 40px;
          }
        }
      }
    }
  }
  .cup-detail {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    /* 左边推荐商品 */
    .cup-t {
      width: 232px;
      /* 推荐商品按钮 */
      .cup-button {
        margin-bottom: 10px;
      }
    }
    .cup-d {
      width: 948px;
      main {
        font-weight: bold;
        width: 948px;
        line-height: 36px;
        background: #efefef;
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
      }
      .img-box {
        width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
